import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";

import { DropdownOptionModel, DropdownOptionValue } from "../../dropdown/models";
import { DropdownOption } from "./dropdown-option";
import { Icons } from "../../../../../enums";
import { Icon } from "../../icon";

import { filterData } from "../../../../../helpers/filter-data";
import { useTranslation } from "../../../../../hooks";
import { T } from "../../../../../constants";

interface DropdownContentProps {
  hasSubKey: string;
  className: string;
  parentKey: string;
  subLabelKey: string;
  withSearch?: boolean;
  isMultiselect?: boolean;
  showBackButton: boolean;
  showSelectAll?: boolean;
  isAllSelected?: boolean;
  backButtonLabel: string;
  onBackClick?: () => void;
  data: Array<DropdownOptionModel>;
  onSubClick?: (value: any) => void;
  selectedItems: Array<DropdownOptionValue>;
  onChange: (value: Array<DropdownOptionValue>) => void;
}

export const DropdownContent: React.FC<DropdownContentProps> = ({
  data,
  onChange,
  hasSubKey,
  parentKey,
  onSubClick,
  onBackClick,
  subLabelKey,
  isMultiselect,
  showSelectAll,
  selectedItems,
  showBackButton,
  className = "",
  backButtonLabel,
  withSearch = false,
}) => {
  const t = useTranslation();
  const [searchValue, setSearchValue] = useState("");

  const selectedItemsMap = useMemo(() => {
    return selectedItems?.reduce<Record<DropdownOptionValue, boolean>>((acc, item) => {
        acc[item] = true;
        return acc;
      }, {});
  }, [selectedItems]);

  const handleSelect = useCallback((value: DropdownOptionValue) => {
    if (isMultiselect) {
      const isOptionSelectedPreviously = selectedItemsMap[value];
      if (isOptionSelectedPreviously) {
        onChange(selectedItems.filter((option) => option !== value));
      } else {
        const newSelectedItems = [...selectedItems, value];
        onChange(newSelectedItems);
      }
    } else {
      onChange([value]);
    }
  }, [isMultiselect, onChange, selectedItemsMap, selectedItems]);

  const filteredData = useMemo(() => {
    return filterData(searchValue, data);
  }, [data, searchValue]);

  const isAllSelected = useMemo(() => {
    return data.every(({value}) => selectedItems.includes(value))
  }, [selectedItems, data]);

  const handleSelectAll = useCallback(() => {
    const currentDataItem = data.map(({ value }) => value);
    const newSelections = isAllSelected ? selectedItems.filter((item) => !currentDataItem.includes(item)) : [...selectedItems, ...currentDataItem];
    onChange(newSelections);
  }, [isAllSelected, data, onChange, selectedItems]);

  const showSearch = withSearch && !!data.length;

  const handleSubClick = useCallback((value: any) => {
    onSubClick?.(value);
  }, []);

  const handleOnBack = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    onBackClick?.();
  }, [onBackClick]);

  const dropdownOptionConfig = useMemo(() => {
    return {
      label: isMultiselect && data?.length ? t(T.SELECT_ALL) : t(T.ALL),
      withCheckbox: isMultiselect && !!data?.length,
    }
  }, [isMultiselect, data]);

  return (
    <div className={`dropdown-content-wr ${className}`}>
      {showSearch && (
        <div className="search-panel">
          <input
            type="text"
            placeholder={t(T.SEARCH)}
            className="search-input"
            onChange={(event) => setSearchValue(event.target.value)}
          />
          <Icon
            className="search-icon"
            type={Icons.Search}
            colorHex="#797a7b"
          />
        </div>
      )}
      <div className="menu">
        {
          showBackButton && (
            <div className="menu-option title-option">
              <div onClick={handleOnBack} className="icon-wr dropdown-icon xs">
                <i className="icon-arrow-left"/>
              </div>
              <p className="nested-dropdown-title">{backButtonLabel}</p>
            </div>
          )
        }
        {showSelectAll && (
          <DropdownOption
            value={t(T.ALL)}
            selected={isAllSelected}
            onSelect={handleSelectAll}
            label={dropdownOptionConfig.label}
            withCheckbox={dropdownOptionConfig.withCheckbox}
          />
        )}
        <div className="menu-contents">
          <div className="menu-options">
            {filteredData.map(({ value, label, disabled, ...data }) => (
              <DropdownOption
                key={value}
                value={value}
                label={label}
                disabled={disabled}
                onSelect={handleSelect}
                withCheckbox={isMultiselect}
                selected={selectedItemsMap[value]}
                hasSubKey={data[hasSubKey]?.length}
                onSubClick={() => handleSubClick({name: data[subLabelKey], parentId: data[parentKey], subParentId: value})}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
