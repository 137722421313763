import React, { memo, useCallback, useState } from "react";

import { generateKey } from "../../../../../../helpers/generate-unique-key";
import { TableColumn, TableRowAction } from "../../models";
import { TableCell } from "../table-cell";
import TableRow from "../table-row";

interface TableBodyProps {
  rowKey?: string;
  columns: Array<TableColumn>;
  rows: Array<Record<string, any>>;
  totals?: Record<string, React.ReactNode>;
  onRowClick: (row: any, index: number) => void;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  getNestedTableData?: (row: any, index: number) => void;
  getRowActions?: (row: any, index: number) => Array<TableRowAction>;
  renderRowNestedChildren?: (row: Record<string, any>, index: number) => React.ReactNode;
}

const TableBody: React.FC<TableBodyProps> = ({
  rows,
  columns,
  onScroll,
  onRowClick,
  rowKey = "id",
  getRowActions,
  renderRowNestedChildren
}) => {

  const [openRowIndex, setOpenRowIndex] = useState<number | null>(null);

  const handleToggleRow = useCallback((rowIndex: number) => {
    setOpenRowIndex((prev) => prev === rowIndex ? null : rowIndex);
  }, [openRowIndex]);

  return (
    <div className="table-bottom">
      <div onScroll={onScroll} className="table-info">
        {/*{totals ? (*/}
        {/*  <TableRow key="totals">*/}
        {/*    {columns.map((col) => (*/}
        {/*      <TableCell key={col.dataKey}>TOTAL</TableCell>*/}
        {/*    ))}*/}
        {/*  </TableRow>*/}
        {/*) : null}*/}
        {rows?.map((row, index) => {
          return (
            <TableRow
              key={row[rowKey] || generateKey(index)}
              actions={getRowActions?.(row, index)} // TODO: optimize if needed
              onClick={() => onRowClick(row, index)}
              handleOpenRow={() => handleToggleRow(index)}
              isOpenNestedChildren={openRowIndex === index}
              nestedChildren={renderRowNestedChildren?.(row, index)}
            >
              {columns?.map((col) => {
                return col.hideRow ? null : (
                  <TableCell key={col.dataKey}>
                    {col.colRenderer?.(
                      col.formatter?.(row[col.dataKey]) || row[col.dataKey],
                      row,
                      index,
                    ) || (
                      <p title={col.formatter?.(row[col.dataKey]) || row[col.dataKey]} className="table-txt">
                        {col.formatter?.(row[col.dataKey]) ||
                          `${row[col.dataKey] ?? "-"}`}
                      </p>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </div>
    </div>
  );
};

export default memo(TableBody);
