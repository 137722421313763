import React, { useCallback, useEffect, useMemo, useState } from "react";

import { TableColumn, TableRowAction } from "../models";
import { TableColumnsRow } from "../table-columns-row";
import { TableColumnType } from "../enums";
import { TableEmpty } from "./table-empty";
import { Loader } from "../../loader";
import TableBody from "./table-body";

interface TableContentProps {
  rowKey?: string;
  noData: boolean;
  loading: boolean;
  columns: Array<TableColumn>;
  rows: Array<Record<string, any>>;
  totals?: Record<string, React.ReactNode>;
  onRowClick: (row: any, index: number) => void;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  getRowActions?: (row: any, index: number) => Array<TableRowAction>;
  renderRowNestedChildren?: (row: Record<string, any>, index: number) => React.ReactNode;
}

export const TableContent: React.FC<TableContentProps> = ({
  rows,
  totals,
  rowKey,
  noData,
  loading,
  columns,
  onScroll,
  onRowClick,
  getRowActions,
  renderRowNestedChildren,
}) => {
  const [rowsData, setRowsData] = useState(rows);

  useEffect(() => {
    setRowsData(rows);
  }, [rows]);

  const sortData = useCallback(
    (dataKey: string, reverse: boolean, type?: TableColumnType) => {
      let filteredData;

      if (type === TableColumnType.Date) {
        filteredData = rowsData.sort(
          (a, b) =>
            new Date(a[dataKey]).getTime() - new Date(b[dataKey]).getTime(),
        );
      } else {
        filteredData = rowsData.sort((a, b) => a[dataKey] - b[dataKey]);
      }

      setRowsData(reverse ? [...filteredData.reverse()] : [...filteredData]);
    },
    [rowsData, setRowsData],
  );

  const showArrowColumn = useMemo(() => {
    return !!renderRowNestedChildren;
  }, [renderRowNestedChildren]);

  return (
    <div className="table-holder">
      <TableColumnsRow showArrowColumn={showArrowColumn} columns={columns} sortData={sortData} />
      {noData || loading ? (
        <div className="table-empty-state">
          {loading ? <Loader /> : <TableEmpty />}
        </div>
      ) : (
        <TableBody
          rows={rowsData}
          rowKey={rowKey}
          totals={totals}
          onScroll={onScroll}
          columns={columns}
          onRowClick={onRowClick}
          getRowActions={getRowActions}
          renderRowNestedChildren={renderRowNestedChildren}
        />
      )}
    </div>
  );
};
