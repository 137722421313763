import EditGamesControl from "../view/pages/games-control/add-edit-game-control";
import { NewPassword } from "../view/pages/login/new-password";
import GamesControl from "../view/pages/games-control";
import { defineGuards } from "./define-guards";
import { lazyWithRetry } from "../helpers";
import { RoutePaths } from "../constants";
import { RouteModel } from "../models";
import { IsAuthLayer } from "./layers";
import { UserType } from "../enums";

const BetHistory = lazyWithRetry(() => import("../view/pages/bet-history"));
const Dashboard = lazyWithRetry(() => import("../view/pages/dashboard"));
const NotFound = lazyWithRetry(() => import("../view/pages/not-found"));
const MainRedirect = lazyWithRetry(() => import("./main-redirect"));

const PartnersList = lazyWithRetry(() => import("../view/pages/partners/partners-list"));
const Partners = lazyWithRetry(() => import("../view/pages/partners"));

const GamesControlList = lazyWithRetry(() => import("../view/pages/games-control/games-control-list"));
const AddEditViewPartner = lazyWithRetry(() => import("../view/pages/partners/add-edit-view-partner"));

const AddEditViewBetshop = lazyWithRetry(() => import("../view/pages/betshops/add-edit-betshop"));
const BetshopList = lazyWithRetry(() => import("../view/pages/betshops/betshop-list"));
const Betshops = lazyWithRetry(() => import("../view/pages/betshops"));

const Configurations = lazyWithRetry(() => import("../view/pages/configurations"));
const Transactions = lazyWithRetry(() => import("../view/pages/transaction-history"));

const AddEditViewManager = lazyWithRetry(() => import("../view/pages/managers/add-edit-manager"));
const ManagerList = lazyWithRetry(() => import("../view/pages/managers/manager-list"));
const Managers = lazyWithRetry(() => import("../view/pages/managers"));

const CreateCashier = lazyWithRetry(() => import("../view/pages/cashiers/add-edit-cashier"));
const CashiersList = lazyWithRetry(() => import("../view/pages/cashiers/cashiers-list"));
const ReportsList = lazyWithRetry(() => import("../view/pages/reports/reports-list"));
const Cashiers = lazyWithRetry(() => import("../view/pages/cashiers"));
const Reports = lazyWithRetry(() => import("../view/pages/reports"));

const AddEditViewBusiness = lazyWithRetry(() => import("../view/pages/business-settings/add-edit-business"));
const BusinessList = lazyWithRetry(() => import("../view/pages/business-settings/business-list"));
const BusinessSettings = lazyWithRetry(() => import("../view/pages/business-settings"));

const AddEditJackpot = lazyWithRetry(() => import("../view/pages/jackpot/add-edit-jackpot"));
const JackpotList = lazyWithRetry(() => import("../view/pages/jackpot/jackpot-list"));
const Jackpots = lazyWithRetry(() => import("../view/pages/jackpot"));

export const MainRoutes: Array<RouteModel> = [
  {
    path: RoutePaths.Empty,
    component: MainRedirect,
  },
  {
    path: RoutePaths.Dashboard,
    component: () => defineGuards([IsAuthLayer], Dashboard),
  },
  {
    path: RoutePaths.BusinessSettings,
    component: () => defineGuards([IsAuthLayer], BusinessSettings),
    nestedRoutes: [
      {
        path: ":id/edit",
        component: () => defineGuards([IsAuthLayer], AddEditViewBusiness),
      },
    ],
  },
  {
    path: RoutePaths.NewPasswordAuth,
    component: () => defineGuards([IsAuthLayer], NewPassword),
  },
  {
    path: RoutePaths.Businesses,
    component: () => defineGuards([IsAuthLayer], BusinessSettings),
    nestedRoutes: [
      {
        path: "",
        component: () => defineGuards([IsAuthLayer], BusinessList),
      },
      {
        path: "add",
        component: () => defineGuards([IsAuthLayer], AddEditViewBusiness),
      },
      {
        path: ":id/edit",
        component: () => defineGuards([IsAuthLayer], AddEditViewBusiness),
      },
    ],
  },
  {
    path: RoutePaths.GameControl,
    component: () => defineGuards([IsAuthLayer], GamesControl),
    nestedRoutes: [
      {
        path: "",
        component: () => defineGuards([IsAuthLayer], GamesControlList),
      },
      {
        path: ":id/edit",
        component: () => defineGuards([IsAuthLayer], EditGamesControl),
      },
    ],
  },
  {
    path: RoutePaths.BetHistory,
    component: () => defineGuards([IsAuthLayer], BetHistory),
  },
  {
    path: RoutePaths.NotFound,
    component: () => defineGuards([IsAuthLayer], NotFound),
  },
  {
    path: RoutePaths.Partners,
    component: () => defineGuards([IsAuthLayer], Partners),
    accessedUserTypes: [UserType.Admin, UserType.SuperAdmin],
    nestedRoutes: [
      {
        path: "",
        component: () => defineGuards([IsAuthLayer], PartnersList),
      },
      {
        path: "add",
        component: () => defineGuards([IsAuthLayer], AddEditViewPartner),
      },
      {
        path: ":id/edit",
        component: () => defineGuards([IsAuthLayer], AddEditViewPartner),
      },
    ],
  },
  {
    path: RoutePaths.Betshops,
    component: () => defineGuards([IsAuthLayer], Betshops),
    nestedRoutes: [
      {
        path: "",
        component: () => defineGuards([IsAuthLayer], BetshopList),
      },
      {
        path: "add",
        component: () => defineGuards([IsAuthLayer], AddEditViewBetshop),
      },
      {
        path: ":id/edit",
        component: () => defineGuards([IsAuthLayer], AddEditViewBetshop),
      },
    ],
  },
  {
    path: RoutePaths.Managers,
    component: () => defineGuards([IsAuthLayer], Managers),
    nestedRoutes: [
      {
        path: "",
        component: () => defineGuards([IsAuthLayer], ManagerList),
      },
      {
        path: "add",
        component: () => defineGuards([IsAuthLayer], AddEditViewManager),
      },
      {
        path: "edit/:id",
        component: () => defineGuards([IsAuthLayer], AddEditViewManager),
      },
    ],
  },
  {
    path: RoutePaths.Cashiers,
    component: () => defineGuards([IsAuthLayer], Cashiers),
    nestedRoutes: [
      {
        path: "",
        component: () => defineGuards([IsAuthLayer], CashiersList),
      },
      {
        path: "add",
        component: () => defineGuards([IsAuthLayer], CreateCashier),
      },
      {
        path: ":id/edit",
        component: () => defineGuards([IsAuthLayer], CreateCashier),
      },
    ],
  },
  {
    path: RoutePaths.Jackpots,
    component: () => defineGuards([IsAuthLayer], Jackpots),
    nestedRoutes: [
      {
        path: "",
        component: () => defineGuards([IsAuthLayer], JackpotList),
      },
      {
        path: "add",
        component: () => defineGuards([IsAuthLayer], AddEditJackpot),
      },
      {
        path: "edit/:id",
        component: () => defineGuards([IsAuthLayer], AddEditJackpot),
      },
    ],
  },
  {
    path: RoutePaths.Reports,
    component: () => defineGuards([IsAuthLayer], Reports),
    nestedRoutes: [
      {
        path: "",
        component: () => defineGuards([IsAuthLayer], ReportsList),
      },
    ],
  },
  {
    path: RoutePaths.Configurations,
    component: Configurations,
    hasNotAccess: [UserType.Manager],
  },
  {
    path: RoutePaths.Transactions,
    component: Transactions,
  },
  {
    path: RoutePaths.WildCard,
    component: NotFound,
  },
];
