import moment, { MomentInput } from "moment";

import { DATE_FORMAT, DATE_FORMAT_BACK, TIME_FORMAT } from "../constants";

export const utcToLocal = (date: MomentInput, format = DATE_FORMAT) => {
  return moment.utc(date).local().format(format);
};

export const dateToUtc = (date: MomentInput, format = DATE_FORMAT_BACK) => {
  return moment.utc(date).format(format);
};

export const formatDate = (date: MomentInput, format = DATE_FORMAT) => {
  return moment(date).format(format);
};

export const convertTimeUtc = (
  time: moment.MomentInput,
  format = DATE_FORMAT_BACK,
) => {
  const selectedMoment = moment(time, format);
  const utcTime = selectedMoment.utc();

  return utcTime.format(format);
};

export const getDefaultDateRange = () => {
  const start = moment().startOf('day').utc().format(DATE_FORMAT_BACK);
  const end = moment().add(1, 'days').startOf('day').utc().format(DATE_FORMAT_BACK);
  return { start, end };
};
