import axios, { AxiosError, AxiosResponse } from "axios";

import { checkResponseStatusAndLogout, combineQuery } from "../helpers";
import { HTTPResponse, QueryModel } from "../models";
import { DEFAULT_ERROR_RESPONSE } from "../constants";

export const http = axios.create({
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/json",
    // 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
  },
  paramsSerializer: {
    serialize: (params: QueryModel) => {
      return combineQuery(params).slice(1);
    },
  },
});

// TODO need create dynamic http axios function
export const httpForFormData = axios.create({
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "multipart/form-data",
  },
  paramsSerializer: {
    serialize: (params: QueryModel) => {
      return combineQuery(params).slice(1);
    },
  },
});

const onFulfilled = (res: AxiosResponse<HTTPResponse<any>>) => res.data.result;

const onRejected = (error: AxiosError) => {
  if (error?.response?.status === 401) {
    checkResponseStatusAndLogout();
  }
  throw error.response?.data || DEFAULT_ERROR_RESPONSE;
};

http.interceptors.response.use(onFulfilled, onRejected);
