import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";

import {
  useStore,
  useTranslation,
  useUpdateEffect,
} from "../../../../../hooks";
import { T } from "../../../../../constants";
import { dataToDropDownConverter } from "../../../../../helpers";
import { MultiselectDropdown } from "../../multiselect-dropdown";

interface BetshopFilterProps {
  value: Array<number>;
  onChange?: (value: Array<number>) => void;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

export const BetshopFilter: React.FC<BetshopFilterProps> = observer(({ value, onChange, setFilters }) => {
    const t = useTranslation();
    const { betshopsStore } = useStore();
    const { betshopsForFilter: { data: betshops } } = betshopsStore;

    useUpdateEffect(() => {
      if (value) {
        const betshop = betshops.find((item) => item.id === +value);
        if (!betshop) {
          setFilters((prev) => {
            const { betshopId, ...restFilters } = prev;
            return restFilters;
          });
        }
      }
    }, [betshops]);

    const betshopsData = useMemo(() => {
      return dataToDropDownConverter(betshops, {
        labelKey: "name",
        valueKey: "id",
      });
    }, [betshops]);

    return (
      <MultiselectDropdown
        withSearch
        value={value}
        label={t(T.BETSHOP)}
        data={betshopsData}
        onChange={(data) => onChange?.(data as Array<number>)}
      />
    );
  },
);
