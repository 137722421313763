import React, { memo } from "react";

import { TableRowAction } from "../../models";
import { Icon } from "../../../icon";

interface TableRowProps {
  onClick?: () => void;
  children: React.ReactNode;
  actions?: Array<TableRowAction>;
  handleOpenRow?: () => void;
  isOpenNestedChildren?: boolean;
  nestedChildren?: React.ReactNode;
}

const TableRow: React.FC<TableRowProps> = ({
  onClick,
  actions,
  children,
  handleOpenRow,
  nestedChildren,
  isOpenNestedChildren
}) => {

  return (
    <div className="table-row" onClick={onClick}>
      <div className={`table-row-inner ${isOpenNestedChildren ? 'open' : ''}`}>
        <div className="table-row-cells">
          {
            nestedChildren && (
              <div onClick={handleOpenRow} className="icon-arrow-wrp">
                <i className="icon-arrow-right"></i>
              </div>
            )
          }
          {children}
        </div>
        {actions?.length ? (
          <div className="table-row-actions-panel">
            {actions.map(({ icon, onClick }) => (
              <Icon
                key={icon}
                type={icon}
                onClick={onClick}
                className="table-row-action"
              />
            ))}
          </div>
        ) : null}
      </div>
      {
        isOpenNestedChildren && (
          nestedChildren
        )
      }
    </div>
  );
};

export default memo(TableRow);
