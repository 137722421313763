import React from "react";

import { Icons } from "../../../../../enums";
import { TableTitleAction } from "../models";
import { Icon } from "../../icon";

interface TableTitleProps {
  title: string;
  onRefreshClick?: () => void;
  titleActions?: Array<TableTitleAction>;
}

export const TableTitle: React.FC<TableTitleProps> = ({
  title,
  titleActions,
  onRefreshClick,
}) => {
  return (
    <div className="table-title-wr">
      <p className="table-title">{title}</p>
      <div className="actions">
        {titleActions?.map(({ icon, onClick, component }) => (
          component || (
            <Icon
              key={icon}
              onClick={onClick}
              type={icon as Icons}
              className="table-title-action"
            />
          )
        ))}
        {onRefreshClick ? (
          <Icon
            type={Icons.Refresh}
            onClick={onRefreshClick}
            className="table-title-action"
          />
        ) : null}
      </div>
    </div>
  );
};
