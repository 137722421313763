import React from "react";

import { TableColumn, TableRowAction, TableTitleAction } from "./models";
import { useTableColumns } from "../../../../hooks";
import { TableContent } from "./table-content";
import { noop } from "../../../../constants";
import { TableHeader } from "./table-header";
import { TableFooter } from "./table-footer";
import { TableTitle } from "./table-title";

import "./style.scss";

export interface TableProps {
  page?: number;
  title?: string;
  rowKey?: string;
  hideFooter?: boolean,
  hideHeader?: boolean,
  rowsCount?: number;
  loading?: boolean;
  selectorValue?: number;
  onRefreshClick?: () => void;
  columns: Array<TableColumn>;
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
  rows: Array<Record<string, any>>;
  titleActions?: Array<TableTitleAction>;
  totals?: Record<string, React.ReactNode>;
  onRowClick?: (row: any, index: number) => void;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  onPaginationChange?: (page: number, selectorValue: number) => void;
  getRowActions?: (row: any, index: number) => Array<TableRowAction>;
  renderRowNestedChildren?: (row: Record<string, any>, index: number) => React.ReactNode;
}

export const Table: React.FC<TableProps> = ({
  rows,
  totals,
  columns,
  onScroll,
  page = 0,
  title = "",
  leftActions,
  hideFooter,
  hideHeader,
  titleActions,
  rightActions,
  getRowActions,
  rowKey = "id",
  onRefreshClick,
  loading = false,
  onRowClick = noop,
  selectorValue = 0,
  onPaginationChange,
  renderRowNestedChildren,
  rowsCount = rows.length,
}) => {

  const {cols} = useTableColumns(columns);
  const pagesCount =  Math.ceil(rowsCount / selectorValue);
  const showFooter = !loading && !!rowsCount && !hideFooter;

  return (
    <div className="table">
      {
        !hideHeader && (
          <TableTitle
            title={title}
            titleActions={titleActions}
            onRefreshClick={onRefreshClick}
          />
        )
      }
      <div className="table-content">
        <TableHeader leftActions={leftActions} rightActions={rightActions}/>
        <TableContent
          rows={rows}
          columns={cols}
          rowKey={rowKey}
          totals={totals}
          loading={loading}
          noData={!rowsCount}
          onScroll={onScroll}
          onRowClick={onRowClick}
          getRowActions={getRowActions}
          renderRowNestedChildren={renderRowNestedChildren}
        />
      </div>
      {showFooter && (
        <TableFooter
          currentPage={page}
          rowsCount={rowsCount}
          pagesCount={pagesCount}
          pageRowsCount={rows.length}
          selectorValue={selectorValue}
          onPaginationChange={onPaginationChange}
        />
      )}
    </div>
  );
};
