import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStore, useTranslation, useUpdateEffect } from "../../../../../hooks";
import { MultiselectDropdown } from "../../multiselect-dropdown";
import { dataToDropDownConverter } from "../../../../../helpers";
import { T } from "../../../../../constants";

interface ManagerFilterProps {
  value: Array<number>;
  onChange?: (value: Array<number>) => void;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

export const ManagerFilter: React.FC<ManagerFilterProps> = observer(({ value, onChange, setFilters }) => {

  const [ parentManagerItem, setParentManagerItem ] = useState<Array<Record<string, any>>>([]);
    const t = useTranslation();
    const { usersStore } = useStore();

    const { managersForFilter: { data: managers } } = usersStore;

    useUpdateEffect(() => {
      if (value) {
        const manager = managers.find((item) => item.id === +value);
        if (!manager) {
          setFilters((prev) => {
            const { managerId, ...restFilters } = prev;
            return restFilters;
          });
        }
      }
      setParentManagerItem([]);
    }, [managers]);

  const lastParentManagerItem = useMemo(() => {
    return parentManagerItem[parentManagerItem.length - 1];
  }, [parentManagerItem]);

  const managerDropdownData = useMemo(() => {
    const filteredManagers = managers.filter((item) => lastParentManagerItem?.subParentId ? item.parentManagerId === lastParentManagerItem?.subParentId : !item.parentManagerId);
    return dataToDropDownConverter(filteredManagers, {
      labelKey: "userName",
      valueKey: "id",
    })
  }, [parentManagerItem, managers, lastParentManagerItem]);

  const showBack = useMemo(() => {
    return !!lastParentManagerItem;
  }, [parentManagerItem]);

  const handleGetSubManagers = useCallback((value: any) => {
    setParentManagerItem((prev) => [...prev, value]);
  }, []);

  const handleOnBack = useCallback(() => {
    const sliceParentManagerItem = parentManagerItem.slice(0, -1);

    setParentManagerItem(sliceParentManagerItem);
  }, [parentManagerItem, setParentManagerItem]);

    return (
        <MultiselectDropdown
          withSearch
          showSelectAll
          value={value}
          label={t(T.MANAGERS)}
          subLabelKey="userName"
          hasSubKey="subManagerIds"
          showBackButton={showBack}
          onBackClick={handleOnBack}
          data={managerDropdownData}
          parentKey="parentManagerId"
          onSubClick={handleGetSubManagers}
          backButtonLabel={lastParentManagerItem?.name}
          onChange={(data) => onChange?.(data as Array<number>)}
        />
    );
  },
);
