import React, { useCallback, useState, useMemo } from "react";

import { FormElementWithPopover } from "../form-element-with-popover";
import { DropdownOptionModel, DropdownOptionValue } from "./models";
import { DropdownContent } from "./dropdown-content";
import { Icons } from "../../../../enums";

// import "./style.scss";
import { useTranslation } from "../../../../hooks";
import { isArray } from "util";

const DROPDOWN_ALL_VALUE = "DROPDOWN_ALL_VALUE";
const DROPDOWN_ALL_LABEL = "ALL";

interface DropdownProps {
  label?: string;
  replacementValue?: string;
  error?: boolean;
  hasAll?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  size?: "sm" | "lg";
  className?: string;
  withSearch?: boolean;
  showSelectAll?: boolean;
  isMultiselect?: boolean;
  isDrawSelectedItem?: boolean;
  value?: DropdownOptionValue;
  checkboxValue?: Array<DropdownOptionValue>;
  data: Array<DropdownOptionModel>;
  direction?: "to-top" | "to-bottom";
  onChange?: (value?: DropdownOptionValue | Array<DropdownOptionValue>) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  size,
  data,
  label,
  error,
  value,
  hasAll,
  readonly,
  disabled,
  onChange,
  withSearch,
  className = "",
  direction = "to-bottom",
  isDrawSelectedItem = true,
  replacementValue,
  isMultiselect,
  showSelectAll,
  checkboxValue,
}) => {
  const t = useTranslation();
  const [open, setOpen] = useState(false);

  const isAllSelected = useMemo(() => {
    return checkboxValue?.length === data.length;
  }, [checkboxValue, data]);

  const handleChange = useCallback(
    (selectedData: Array<DropdownOptionValue>) => {
      const [valueOrAll] = selectedData;

      if (!isMultiselect) {
        if (`${valueOrAll}` === `${value}`) return;
        if (onChange) {
          const value =
            valueOrAll === DROPDOWN_ALL_VALUE ? undefined : valueOrAll;
          onChange(value);
        }
        setOpen(false);
      } else if (onChange && checkboxValue) {
        if (valueOrAll === DROPDOWN_ALL_VALUE) {
          onChange(isAllSelected ? [] : data.map(({ value }) => value));
          return;
        }

        onChange(
          checkboxValue.includes(valueOrAll)
            ? checkboxValue.filter((item) => item !== valueOrAll)
            : [...checkboxValue, valueOrAll],
        );
      }
    },
    [onChange, value, checkboxValue, isMultiselect, data, isAllSelected],
  );

  const selectedValueLabel = useMemo(() => {
    const selectedItem = data.find((item) => `${item.value}` === `${value}`);
    return selectedItem?.label || (hasAll ? DROPDOWN_ALL_LABEL : "");
  }, [data, value, hasAll]);

  const dropdownData = useMemo(() => {
    return hasAll
      ? [{ value: DROPDOWN_ALL_VALUE, label: DROPDOWN_ALL_LABEL }, ...data]
      : data;
  }, [data, hasAll]);

  const dropdownValue = useMemo(() => {
    if (isMultiselect) return checkboxValue;

    return value ? [value] : [DROPDOWN_ALL_VALUE];
  }, [value, checkboxValue, isMultiselect]);

  return (
    <FormElementWithPopover
      open={open}
      size={size}
      error={error}
      label={label}
      setOpen={setOpen}
      readonly={readonly}
      disabled={disabled}
      className={className}
      icon={Icons.ArrowDown}
      value={
        !selectedValueLabel && replacementValue
          ? replacementValue
          : typeof selectedValueLabel === "string"
            ? t(selectedValueLabel)
            : selectedValueLabel
      }
    >
      <DropdownContent
        data={
          isDrawSelectedItem
            ? dropdownData
            : dropdownData.filter((item) => item.value !== value)
        }
        className={direction}
        onChange={handleChange}
        withSearch={withSearch}
        showSelectAll={showSelectAll}
        selectedItems={dropdownValue as DropdownOptionValue[]}
        isMultiselect={isMultiselect}
        isAllSelected={isAllSelected}
      />
    </FormElementWithPopover>
  );
};
