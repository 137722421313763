import { action, makeObservable, observable } from "mobx";

import { DEFAULT_RESPONSE_LIST } from "../constants";
import {GetJackpotsRequest, JackpotsResponse} from "../models";
import { HttpResponse, jackpotController } from "../api";
import { RootStore } from "./index";

export class JackpotsStore {

  private rootStore: RootStore;

  jackpots = new HttpResponse<JackpotsResponse>(DEFAULT_RESPONSE_LIST);

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      jackpots: observable,
      setJackpots: action.bound,
    });
  };

  public setJackpots(value: HttpResponse<JackpotsResponse>): void {
    this.jackpots = value;
  }

  public async getJackpots(params: GetJackpotsRequest): Promise<void> {
    this.setJackpots(this.jackpots.fetching());
    const data = await jackpotController.getJackpots(params);
    this.setJackpots(this.jackpots.fetched(data));
  }

}
