import React, {SyntheticEvent, useCallback} from "react";

import { DropdownOptionValue} from "../../../dropdown/models";
import { Checkbox } from "../../../checkbox";

interface DropdownOptionProps {
  hasSubKey?: string;
  selected: boolean;
  disabled?: boolean;
  label: string | number;
  withCheckbox?: boolean;
  indeterminate?: boolean;
  value: DropdownOptionValue;
  onSelect: (value: DropdownOptionValue) => void;
  onSubClick?: (value?: DropdownOptionValue) => void;
}

export const DropdownOption: React.FC<DropdownOptionProps> = ({
  value,
  label,
  onSelect,
  selected,
  disabled,
  hasSubKey,
  onSubClick,
  withCheckbox,
  indeterminate,
}) => {

  const handleOnClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    onSubClick?.();
  }, []);

  return (
    <div
      onClick={() => onSelect(value)}
      className={`menu-option default-option ${selected ? "active" : ""} ${disabled ? "disabled" : ""}`}
    >
      <div className="menu-content">
        {withCheckbox && (
            <Checkbox checked={selected} indeterminate={indeterminate} />
        )}
        <p className="checkbox-label">{label}</p>
        {
          !!hasSubKey && (
            <div onClick={handleOnClick} className="icon-wr dropdown-icon right-arrow xs">
              <i className="icon-arrow-right"/>
            </div>
          )
        }
      </div>
    </div>
  );
};
