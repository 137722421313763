import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react-lite";

import { getCurrentLanguageCode } from "../../../helpers";
import { Loader } from "../../components/shared";
import { useStore } from "../../../hooks";

interface SplashProps {
  children: React.ReactNode;
}

export const Splash: React.FC<SplashProps> = observer(({ children }) => {
  const { authStore, translationsStore, appStore } = useStore();
  const { splashLoading, appLoading } = appStore;

  useEffect(() => {
    refreshToken();
  }, []);

  const refreshToken = useCallback(async () => {
    await authStore.refresh();
    translationsStore.getTranslations({
      code: getCurrentLanguageCode(),
    });
    appStore.setSplashLoading(false);
    appStore.setAppLoading(false);
  }, []);

  return (
    <>
      {splashLoading ? null : children}
      {splashLoading || appLoading ? <Loader /> : null}
    </>
  );
});
