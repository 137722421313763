import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";

import { hasPermission } from "../../../../helpers";
import { useStore } from "../../../../hooks";
import { UserType } from "../../../../enums";
import { BusinessFilter } from "./business";
import { PartnerFilter } from "./partner";
import { ManagerFilter } from "./manager";
import { BetshopFilter } from "./betshop";
import { CashierFilter } from "./cashier";

interface MainFiltersProps {
  hidePartners?: boolean;
  hideManagers?: boolean;
  hideBetshops?: boolean;
  isReset?: boolean;
  hideCashiers?: boolean;
  filters: Record<string, any>;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

export const MainFilters: React.FC<MainFiltersProps> = observer(
  ({
    filters,
    isReset,
    setFilters,
    hidePartners = false,
    hideManagers = false,
    hideBetshops = false,
    hideCashiers = false,
  }) => {
    const { betshopsStore, partnerStore, usersStore, userSettingsStore } = useStore();

    const { userSettings: { data: { userType } } } = userSettingsStore;

    const { selectedBusiness } = partnerStore;

    const { partnerId, betshopIds = [], cashierIds, businessUnitId, managerIds = [] } = filters;

    const showBetshops = !hideBetshops;
    const showCashiers = !hideCashiers;

    const showPartners =
      !hidePartners &&
      hasPermission(userType as UserType, [
        UserType.SuperAdmin,
        UserType.Admin,
      ]);

    const showManagers =
      !hideManagers &&
      hasPermission(userType as UserType, [
        UserType.SuperAdmin,
        UserType.Admin,
        UserType.Partner,
      ]);

    useEffect(() => {
      if (!userType) return;
      if (userType === UserType.Partner) {
        if (selectedBusiness.id) {
          setFilters({});
          getAllUsers();
        }
      } else {
        getAllUsers();
      }
      return () => {
        betshopsStore.resetBetShopFilteredData();
        usersStore.resetFilteredData();
      };
    }, [userType, selectedBusiness.id]);

    useEffect(() => {
      if (isReset) {
        getAllUsers();
      }
    }, [isReset]);

    const getAllUsers = useCallback(() => {
        getPartners();
        getBusinesses();
        getManagers(partnerId);
        getBetshops(partnerId, managerIds);
        getCashiers(partnerId, betshopIds);
    }, [partnerId, managerIds, betshopIds, selectedBusiness.id]);

    // START --------- filters api calls
    const getPartners = useCallback(() => {
      if (!showPartners) return;
      partnerStore.getPartnersForFilter();
    }, [showPartners]);

    const getBusinesses = useCallback(() => {
      if (!showPartners) return;
      partnerStore.getPartnerBusiness();
    }, [showPartners]);

    const getManagers = useCallback((partnerId?: number, businessUnitId?: number) => {
        if (!showManagers) return;
        usersStore.getManagersForFilter({
          partnerId,
          userType: UserType.Manager,
          businessUnitId: selectedBusiness.id || businessUnitId,
        });
      },
      [showManagers, businessUnitId, selectedBusiness, partnerId],
    );

    const getBetshops = useCallback((partnerId?: number, businessUnitId?: number, managerIds?: Array<number>) => {
        if (!showBetshops) return;
        betshopsStore.getBetshopsForFilter({
          partnerId,
          managerIds,
          businessUnitId: selectedBusiness.id || businessUnitId,
        });
      }, [showBetshops, businessUnitId, selectedBusiness]);

    const getCashiers = useCallback((
        partnerId?: number,
        businessUnitId?: number,
        managerIds?: Array<number>,
        betshopIds?: Array<number>,
      ) => {
        if (!showCashiers) return;

        usersStore.getCashiersForFilter({
          partnerId,
          managerIds,
          betshopIds,
          userType: UserType.Cashier,
          businessUnitId: selectedBusiness.id || businessUnitId,
        });
      }, [showCashiers, businessUnitId, selectedBusiness.id, managerIds, betshopIds]);

    const handlePartnerChange = useCallback((partnerId?: number) => {
        getManagers(partnerId);
        getBetshops(partnerId);
        getCashiers(partnerId);
        setFilters((prev) => ({
          ...prev,
          partnerId,
          businessUnitId: null,
          managerIds: [],
          betshopIds: [],
          cashierIds: [],
        }));
        partnerStore.getPartnerBusiness({ partnerId });
      }, [getManagers, getBetshops, getCashiers, setFilters]);

    const handleBusinessChange = useCallback((businessUnitId?: number) => {
        getManagers(partnerId, businessUnitId);
        getCashiers(partnerId, businessUnitId);
        getBetshops(partnerId, businessUnitId);
        setFilters((prev) => ({
          ...prev,
          businessUnitId,
          managerIds: [],
          betshopIds: [],
          cashierIds: [],
        }));
      },
      [setFilters, partnerId, betshopIds, managerIds, selectedBusiness],
    );

    const handleManagerChange = useCallback((managerIds: Array<number>) => {
        getBetshops(partnerId, businessUnitId, managerIds);
        getCashiers(partnerId, businessUnitId, managerIds);
        setFilters((prev) => ({
          ...prev,
          managerIds,
          betshopIds: [],
          cashierIds: [],
        }));
      }, [
        partnerId,
        betshopIds,
        getBetshops,
        getCashiers,
        businessUnitId,
        setFilters,
      ]);

    const handleBetshopChange = useCallback((betshopIds: Array<number>) => {
      getCashiers(partnerId, businessUnitId, managerIds, betshopIds);
      setFilters((prev) => ({
        ...prev,
        betshopIds,
        cashierIds: [],
      }));
    }, [partnerId, managerIds, getCashiers, businessUnitId, setFilters]);

    const handleCashierChange = useCallback((cashierIds?: number) => {
        setFilters((prev) => ({ ...prev, cashierIds }));
      }, [setFilters]);

    return (
      <>
        {showPartners ? (
          <>
            <PartnerFilter
              value={partnerId}
              onChange={(value) => handlePartnerChange(value as number)}
            />
            <BusinessFilter
              hasAll
              value={businessUnitId}
              partnerId={filters.partnerId}
              onChange={(value) => handleBusinessChange(value as number)}
            />
          </>
        ) : null}

        {showManagers && (
          <ManagerFilter
            value={managerIds}
            setFilters={setFilters}
            onChange={handleManagerChange}
          />
        )}
        {showBetshops && (
          <BetshopFilter
            value={betshopIds}
            setFilters={setFilters}
            onChange={(value) => handleBetshopChange(value)}
          />
        )}
        {showCashiers && (
          <CashierFilter
            value={cashierIds}
            setFilters={setFilters}
            onChange={(value) => handleCashierChange(value as number)}
          />
        )}
      </>
    );
  },
);
