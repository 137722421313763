import { ReportGroups } from "../enums";
import { T } from "./translation-keys";
import { DropdownOptionModel } from "../view/components/shared/dropdown/models";

export const ReportGroupNames = {
  [ReportGroups.Business]: T.BUSINESS,
  [ReportGroups.Betshop]: T.BETSHOP,
  [ReportGroups.Manager]: T.MANAGER,
  [ReportGroups.Cashier]: T.CASHIER,
  [ReportGroups.Partner]: T.PARTNER,
  [ReportGroups.Game]: T.GAME,
};

export const ReportGroupsDropdownData: Array<DropdownOptionModel> = [
  {
    value: ReportGroups.Business,
    label: ReportGroupNames[ReportGroups.Business],
  },
  {
    value: ReportGroups.Betshop,
    label: ReportGroupNames[ReportGroups.Betshop],
  },
  {
    value: ReportGroups.Manager,
    label: ReportGroupNames[ReportGroups.Manager],
  },
  {
    value: ReportGroups.Cashier,
    label: ReportGroupNames[ReportGroups.Cashier],
  },
  {
    value: ReportGroups.Partner,
    label: ReportGroupNames[ReportGroups.Partner],
  },
  {
    value: ReportGroups.Game,
    label: ReportGroupNames[ReportGroups.Game],
  },
];
