import React, { useCallback, useState, useMemo } from "react";

import { DropdownOptionModel, DropdownOptionValue } from "../dropdown/models";
import { FormElementWithPopover } from "../form-element-with-popover";
import { DropdownContent } from "./dropdown-content";
import { Icons } from "../../../../enums";

interface MultiselectDropdownProps {
  label: string;
  error?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  size?: "sm" | "lg";
  hasSubKey?: string;
  withSearch?: boolean;
  parentKey?: string;
  subLabelKey?: string;
  showSelectAll?: boolean;
  backButtonLabel?: string;
  showBackButton?: boolean;
  onBackClick?: () => void;
  setSelectedItems?: () => void;
  selectedItems?: Array<number>;
  selectAllIsMultiLevel?: boolean;
  data: Array<DropdownOptionModel>;
  direction?: "to-top" | "to-bottom";
  onSubClick?: (value: any) => void;
  onChange?: (value: Array<DropdownOptionValue>) => void;
  value: Array<DropdownOptionValue> | DropdownOptionValue;
}

export const MultiselectDropdown: React.FC<MultiselectDropdownProps> = ({
  size,
  data,
  label,
  error,
  readonly,
  disabled,
  onChange,
  onSubClick,
  value = [],
  withSearch,
  onBackClick,
  showSelectAll,
  hasSubKey = "",
  parentKey = "",
  subLabelKey = "",
  backButtonLabel = "",
  showBackButton = false,
  direction = "to-bottom",
}) => {
  const [open, setOpen] = useState(false);

  const selectedItems = useMemo<Array<DropdownOptionValue>>(() => Array.isArray(value) ? value : [value], [value]);

  const handleChange = useCallback((data: Array<DropdownOptionValue>) => onChange?.(data), [onChange]);

  const selectedValueLabel = useMemo(() => {
    const selectedLabels = data.filter((item) => selectedItems.includes(item.value));
    return selectedLabels.map(({ label }) => label).join(", ") || null;
  }, [data, selectedItems]);

  return (
    <FormElementWithPopover
      open={open}
      size={size}
      error={error}
      label={label}
      setOpen={setOpen}
      readonly={readonly}
      disabled={disabled}
      icon={Icons.ArrowDown}
      value={selectedValueLabel}
    >
      <DropdownContent
        data={data}
        isMultiselect
        hasSubKey={hasSubKey}
        parentKey={parentKey}
        className={direction}
        withSearch={withSearch}
        onChange={handleChange}
        onSubClick={onSubClick}
        subLabelKey={subLabelKey}
        onBackClick={onBackClick}
        selectedItems={selectedItems}
        showSelectAll={showSelectAll}
        showBackButton={showBackButton}
        backButtonLabel={backButtonLabel}
      />
    </FormElementWithPopover>
  );
};
