import { action, makeObservable, observable } from "mobx";

import { RootStore } from "./index";

export class ToggleStore {

  private rootStore: RootStore;

  public buttonLoading = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      buttonLoading: observable,
      setButtonLoading: action.bound,
    });
  }

  setButtonLoading = (state: boolean) => {
    this.buttonLoading = state;
  }

}
