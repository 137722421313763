import {AddBetshopData, Betshop, GetJackpotsRequest, JackpotsResponse} from "../../../models";
import { BaseApiService } from "../../base-api-service";
import { ApiVersions } from "../../../enums";
import { env } from "../../../environment";

class JackpotsController extends BaseApiService {

  public async getJackpots(params?: GetJackpotsRequest,): Promise<JackpotsResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/jackpot`;
    return await this.http.get(url, { params });
  }

  public async addJackpot(data: AddBetshopData): Promise<Betshop> {
    const url = `${this.url}/api/${ApiVersions.V1}/jackpot/mega`;
    return await this.http.post(url, data);
  }

}

export const jackpotController = new JackpotsController({
  url: env.httpUrl,
});
